<template>
  <vx-card :title="this.pageTitle">
    <template slot="actions">
      <div class="flex">
        <vs-button
          v-if="$can('retrieve', 'product')"
          size="small" color="success" type="border" icon-pack="feather" icon="icon-book-open" class="mr-2"
          @click="requestReportGeneration()"
        >
          Crear Reporte Rápido
        </vs-button>
        <vs-button
          v-if="$can('retrieve', 'product')"
          size="small" color="success" type="border" icon-pack="feather" icon="icon-settings"
          @click="goToScheduledReports()"
        >
          Reportes Programados
        </vs-button>
      </div>
    </template>

    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-table max-items="10" pagination search stripe :data="entries" :hoverFlat="true">
          <template slot="thead">
            <vs-th sort-key="unix_created_at">Fecha</vs-th>
            <vs-th sort-key="personal_identification">Identificación</vs-th>
            <vs-th sort-key="full_name">Nombre</vs-th>
            <vs-th sort-key="email">Email</vs-th>
            <vs-th sort-key="phone_number">Teléfono</vs-th>
          </template>

          <template slot-scope="{data}">
            <tbody>
            <vs-tr :data="record" :key="index" v-for="(record, index) in data">
              <vs-td>
                <p class="font-medium">{{ record.created_at | local_datetime }}</p>
              </vs-td>
              <vs-td>
                <p class="font-medium">{{ record.personal_identification }}</p>
              </vs-td>
              <vs-td>
                <p class="font-medium">{{ record.full_name }}</p>
              </vs-td>
              <vs-td>
                <p class="font-medium">{{ record.email }}</p>
              </vs-td>
              <vs-td>
                <p class="font-medium">{{ record.phone_number }}</p>
              </vs-td>

              <template slot="expand">
                <div class="vx-row w-full mr-2 ml-2">
                  <div class="vx-col w-1/3">
                    <vs-list>
                      <vs-list-item
                        size="large" icon-pack="feather" icon="icon-user"
                        title="Nombre" :subtitle="record.full_name"
                      />
                      <vs-list-item
                        size="large" icon-pack="feather" icon="icon-hash"
                        title="Identificación" :subtitle="record.personal_identification"
                      />
                      <vs-list-item
                        size="large" icon-pack="feather" icon="icon-mail"
                        title="Email" :subtitle="record.email"
                      />
                      <vs-list-item
                        size="large" icon-pack="feather" icon="icon-phone"
                        title="Teléfono" :subtitle="record.phone_number"
                      />
                    </vs-list>
                  </div>
                  <div class="vx-col w-2/3">
                    <vs-list>
                      <vs-list-item class="text-justify" title="Consulta" :subtitle="record.message"/>
                    </vs-list>
                  </div>
                </div>
              </template>
            </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </div>
  </vx-card>
</template>

<script>
  import CRMClient from "../../utils/CRMClient";
  import EntriesClient from "../../utils/entriesClient";
  import Notifier from "./../../utils/notification";

  let notifier = new Notifier();
  const FORMS_CLIENT = new CRMClient("products");
  const ENTRIES_CLIENT = new EntriesClient("products");

  export default {
    data() {
      return {
        pageTitle: "Inbox",
        recipients: [],
        newRecipient: {
          name: "",
          email: ""
        },
        popupActive: false,
        entries: [],
      };
    },

    mounted() {
      this.$vs.loading();
      this.setPageTitle()
        .then(() => {
          this.getForms()
            .then(() => {
              this.$vs.loading.close();
            });
        });
    },

    computed: {
      validateForm() {
        return this.newRecipient.name !== '' && this.newRecipient.email !== "";
      }
    },

    methods: {
      async setPageTitle() {
        await ENTRIES_CLIENT.retrieve({
          queryStrings: {representation_type: "simple"},
          pathParameters: {entryId: this.$route.params.entryId}
        })
          .then(response => {
            this.pageTitle = `Inbox: ${response.data.name}`
          })
          .catch(error => {
            notifier.notification("error");
          });
      },

      async getForms() {
        await FORMS_CLIENT.all({pathParameters: {entryId: this.$route.params.entryId}})
          .then(response => {
            this.entries = response.data;
          })
          .catch(error => {
            notifier.notification("error");
          });
      },

      requestReportGeneration() {
        this.$router.push({name: "generate-report-product-forms", params: {id: this.$route.params.entryId}});
      },

      goToScheduledReports() {
        this.$router.push({name: "list-scheduled-reports-product", params: {entryId: this.$route.params.entryId}});
      },
    },
  };
</script>

<style scoped>
  .vs-table--tbody-table .tr-values.selected {
    cursor: default;
  }

  .block-btn {
    margin: auto;
    display: block;
    width: 100%;
  }

  .email-recipient {
    word-break: break-word;
  }
</style>
